exports.components = {
  "component---src-sites-type-ak-mag-polsecurity-templates-add-domain-tsx": () => import("./../../../src/sites/typeAk/mag-polsecurity/templates/AddDomain.tsx" /* webpackChunkName: "component---src-sites-type-ak-mag-polsecurity-templates-add-domain-tsx" */),
  "component---src-sites-type-ak-mag-polsecurity-templates-category-tsx": () => import("./../../../src/sites/typeAk/mag-polsecurity/templates/Category.tsx" /* webpackChunkName: "component---src-sites-type-ak-mag-polsecurity-templates-category-tsx" */),
  "component---src-sites-type-ak-mag-polsecurity-templates-company-tsx": () => import("./../../../src/sites/typeAk/mag-polsecurity/templates/Company.tsx" /* webpackChunkName: "component---src-sites-type-ak-mag-polsecurity-templates-company-tsx" */),
  "component---src-sites-type-ak-mag-polsecurity-templates-contact-tsx": () => import("./../../../src/sites/typeAk/mag-polsecurity/templates/Contact.tsx" /* webpackChunkName: "component---src-sites-type-ak-mag-polsecurity-templates-contact-tsx" */),
  "component---src-sites-type-ak-mag-polsecurity-templates-home-tsx": () => import("./../../../src/sites/typeAk/mag-polsecurity/templates/Home.tsx" /* webpackChunkName: "component---src-sites-type-ak-mag-polsecurity-templates-home-tsx" */),
  "component---src-sites-type-ak-mag-polsecurity-templates-not-found-tsx": () => import("./../../../src/sites/typeAk/mag-polsecurity/templates/NotFound.tsx" /* webpackChunkName: "component---src-sites-type-ak-mag-polsecurity-templates-not-found-tsx" */),
  "component---src-sites-type-ak-mag-polsecurity-templates-search-tsx": () => import("./../../../src/sites/typeAk/mag-polsecurity/templates/Search.tsx" /* webpackChunkName: "component---src-sites-type-ak-mag-polsecurity-templates-search-tsx" */),
  "component---src-sites-type-ak-mag-polsecurity-templates-terms-tsx": () => import("./../../../src/sites/typeAk/mag-polsecurity/templates/Terms.tsx" /* webpackChunkName: "component---src-sites-type-ak-mag-polsecurity-templates-terms-tsx" */)
}

